import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Component({
  name: 'GtrAdminSetEventView',
  computed: {
    ...mapState('scans', ['scan_data']),
    ...mapState('event', ['events'])
  },
  components: {
  }
})
export default class GtrAdminSetEventView extends Vue {
  data () {
    return {
      eventSelectedUuid: '',
      events2: [],
      search: '',
      loading: false,
      table: {
        headers: [
          { text: 'Barcode', align: 'start', sortable: true, value: 'barcode_num' },
          { text: 'Date Scanned', value: 'date_time' },
          { text: 'Line #', value: 'line' },
          { text: 'Actions', value: 'actions', searchable: false, sortable: false, width: '90px' }
        ],
        items: []
      },
      forms: {
        showNewCompanyForm: false
      },
      companyToDelete: null
    }
  }

  @Watch('scan_data')
  onScanDataChange (payload: any) {
    if (payload) {
      this.$data.table.items = payload
    }
  }

  async mounted () {
    this.$store.dispatch('scans/fetchScansByFileNameAndDeviceId', { file_name: this.$route.params.file_name, deviceid: this.$route.params.deviceid })
    this.fetchEvents()
  }

  handleChangeEvent () {
    const event_uuid = this.$data.eventSelectedUuid
    this.$store.dispatch('scans/changeEvent', { file_name: this.$route.params.file_name, deviceid: this.$route.params.deviceid, event_uuid })
  }

  private async fetchEvents () {
    try {
      this.$data.loading = true
      const response = await this.$store.dispatch('event/loadEvents')
      if (response.data.length > 0) {
        for (const itemIndex in response.data) {
          const company = response.data[itemIndex]
          if (company) {
            for (const eventIndex in company.events) {
              const event = company.events[eventIndex]
              this.$data.events2.push({ text: company.name + ' : ' + event.event_identifier, value: event.uuid })
            }
          }
        }
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
}
